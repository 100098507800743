// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-content {
  p {
    margin: 0 !important;
  }
}

.ant-menu-sub.ant-menu-inline {
  background-color: white !important;
}

.mailCard > .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.7rem;
  padding: 0.5rem 2rem;
}

.mailCardActive {
  border: 1px solid #108ee9 !important;
}

.addEmailModal .ant-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 1rem;
}

.ant-btn {
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-layout-sider-children {
  max-width: 100% !important;
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/antd.css"],"names":[],"mappings":"AAAA;EACE;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;;EAEnB,SAAS;AACX;;AAEA;EACE;IACE,gBAAgB;IAChB,uBAAuB;EACzB;AACF;;AAEA;EACE,0BAA0B;EAC1B,sBAAsB;AACxB","sourcesContent":[".ant-layout-content {\n  p {\n    margin: 0 !important;\n  }\n}\n\n.ant-menu-sub.ant-menu-inline {\n  background-color: white !important;\n}\n\n.mailCard > .ant-card-body {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 0.7rem;\n  padding: 0.5rem 2rem;\n}\n\n.mailCardActive {\n  border: 1px solid #108ee9 !important;\n}\n\n.addEmailModal .ant-modal-body {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n\n  gap: 1rem;\n}\n\n.ant-btn {\n  span {\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n\n.ant-layout-sider-children {\n  max-width: 100% !important;\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
